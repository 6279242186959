<template>
    <div class="message-detail">
        <!-- {{liveInfo}} -->
        <div v-if="isPC" class="pccontainer" v-loading="loading">
            <!-- <div class="">{{liveInfo.title}}</div> -->
            <!-- <div> {{this.dateTimeFormate(new Date(liveInfo.startTime))}}</div> -->
            <div style="width:100%;text-align:left;" @click="back" class="back"><i class="el-icon-arrow-left">返回</i></div>
            <br />
            <el-descriptions title="直播信息" labelStyle="width:100px;background: #FDE2E2;" class="margin-top"  :column="1" border>
                <el-descriptions-item  label="直播名称">{{liveInfo.title}}</el-descriptions-item>
                <el-descriptions-item  label="直播时间">{{dateTimeFormate(new Date(liveInfo.startTime))}}</el-descriptions-item>
                <el-descriptions-item  label="直播介绍">
                    <div v-html="liveInfo.introduce" ></div>
                </el-descriptions-item>
                <el-descriptions-item label="操作"><el-button @click="toplayer()" style="width:100%" type="primary">观看直播</el-button></el-descriptions-item>
            </el-descriptions>
        </div>
        <div v-else class="mbcontainer" v-loading="loading">
           <!-- <div class="">{{liveInfo.title}}</div> -->
            <!-- <div> {{this.dateTimeFormate(new Date(liveInfo.startTime))}}</div> -->
            <div style="width:100%;text-align:left;" @click="back" class="back"><i class="el-icon-arrow-left">返回</i></div>
            <br />
            <el-descriptions title="直播信息" labelStyle="width:100px;background: #FDE2E2;" class="margin-top"  :column="1" border>
                <el-descriptions-item  label="直播名称">{{liveInfo.title}}</el-descriptions-item>
                <el-descriptions-item  label="直播时间">{{dateTimeFormate(new Date(liveInfo.startTime))}}</el-descriptions-item>
                <el-descriptions-item  label="直播介绍">
                    <div v-html="liveInfo.introduce" ></div>
                </el-descriptions-item>
                <el-descriptions-item label="操作"><el-button @click="toplayer()" style="width:100%" type="primary">观看直播</el-button></el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            liveInfo:'',
            isPC:true, 
            loading:true,
        }
    },
    mounted() {
        this.getLiveData()
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        // alert(flag)
        if(flag){
            this.isPC = false;
            // alert("移动端")
        }
    },
    methods: {
         dateTimeFormate(dateStr) {
            var dt = new Date(dateStr);
            return dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0') +
                ' ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0') + ':' + dt.getSeconds().toString().padStart(2, '0');
        },
        getLiveData(){
            this.loading = true;
            this.$api.getLiveSingleInfo({id:this.$route.query.id}).then((res)=>{
                console.log(res);
                this.liveInfo = res.data.data
                this.loading = false
            })
        },
        toplayer(){
            window.open("http://player.gzsrlzy.com/?roomId="+this.liveInfo.id) //hhdglm
        },
        back(){
        this.$router.push({
            path:'/message',
        
        })
        }
    }
}
</script>

<style scoped>
.back {
    border-bottom: 1px solid #ccc;
    padding: 5px;
    cursor: pointer;
}
.pccontainer {
    width: 60%;
    margin: 0 auto;
}
.mbcontainer {
    width: 100%;
    /* margin: 0 auto; */
}
</style>